// type
export const darkmain = '#33435a';
export const mainblue = '#0a6dff';
export const maintext = '#333333';
export const maingrey = '#efefef';
export const darkblue = '#001432';
export const blue = '#579AE7';
export const white = '#FFFFFF';
export const black = '#000000';
export const darkgreen =  '#072924';
export const eucalyptus = '#5F8575';
export const buttonmain =  '#0a6dff';
export const buttonmainhover =  '#0058ff';
export const cardheader =  '#121519';
export const error1 = '#FF4140';
export const error2 = '#FFD9D9';
export const error3 = '#FFF5F5';
export const cancel = '#00A685';
export const cancelbg = '#00FFCD';


// secondary
// 1st level
export const tourquoise = '#6EC4C3';
export const orange1 = '#ED7F43';
export const burntorange = '#fd782e'

// 2nd level
export const green1 = '#3D8B73';
export const green2 = '#4FC486';
export const green3 = '#A2E8AB';
export const lightblue = '#89D1F4';
export const lightbluemodel = '#56cac5';
export const red = '#E3453D';
export const orange2 = '#F8B065';
export const green4 = '#83EDB3';
export const columbiablue = '#A2D8FF';
export const skyblue = '#02A6FA';
export const tangerine = '#FFB11F';
export const cinnabar = '#ED3838';

// hover
export const bluedarker = '#367DCE';

// background
export const grey90 = '#1c2430';
export const grey80 = '#33435B';
export const grey70 = '#4D5B6F';
export const grey60 = '#667284';
export const grey40 = '#A9A9A9';
export const grey30 = '#B1B7C0';
export const grey20 = '#CED3DB';
export const grey10 = '#DBDEE5';
export const grey8 = '#e4e7ee';
export const grey7 = '#e3e6e8';
export const grey5 = '#F2F3F4';
export const grey6 = '#C4C4C4';
export const limegreen = '#03DDC7';
export const blackpearl = '#152129';
export const blackpearl2 = '#121519';
export const blackpearl3 = '#0D2226';
export const bunker = '#1D222A';

// error
export const errorred = '#FF5655';
export const errorred20 = '#F5D4D4';
export const errorred5 = '#FDF6F6';
export const errorred2 = '#FF4140';
export const errorred3 = '#FFD9D9';
export const errorred4 = '#FFF5F5';

// options for sanity preview
export const option_color1 = '#EAE7DC';
export const option_color2 = '#33435B';
export const option_color3 = '#667284';
export const option_color4 = '#3AB0FF';
export const option_color5 = '#E85A4F';
export const option_color6 = '#116466';
export const option_color7 = '#A64AC9';
export const option_color8 = '#28784D';
export const option_color9 = '#F8B065';
