import React from 'react';

import { ReactComponent as add } from './Svg/add.svg';
import { ReactComponent as add_small } from './Svg/add_small.svg';
import { ReactComponent as add_vault_file } from './Svg/add_vault_file.svg';
import { ReactComponent as add_version } from './Svg/add_version.svg';
import { ReactComponent as ai_circle_white} from './Svg/ai_circle_white.svg'
import { ReactComponent as alert} from './Svg/alert.svg'
import { ReactComponent as analysis_wizard} from './Svg/analysis_wizard.svg'
import { ReactComponent as anonymization} from './Svg/anonymization.svg'
import { ReactComponent as anonymization_color} from './Svg/anonymization_color.svg'
import { ReactComponent as assist} from './Svg/assist.svg'
import { ReactComponent as caution_error } from './Svg/icon_caution_Error–Red.svg';
import { ReactComponent as check_filled } from './Svg/check_filled.svg';
import { ReactComponent as circle } from './Svg/circle.svg';
import { ReactComponent as circle_2 } from './Svg/circle_2.svg';
import { ReactComponent as circle_3 } from './Svg/circle_3.svg';
import { ReactComponent as close } from './Svg/close.svg';
import { ReactComponent as close_small } from './Svg/close_small.svg';
import { ReactComponent as collapse_all } from './Svg/collapse_all.svg';
import { ReactComponent as contact_mail } from './Svg/contact_mail.svg';
import { ReactComponent as contact_phone } from './Svg/contact_phone.svg';
import { ReactComponent as check } from './Svg/check.svg';
import { ReactComponent as click } from './Svg/click-svgrepo-com.svg';
import { ReactComponent as costs_outline } from './Svg/costs_outline.svg';
import { ReactComponent as credits } from './Svg/credits.svg';
import { ReactComponent as data_analysis } from './Svg/data_analysis.svg';
import { ReactComponent as data_down } from './Svg/data_down.svg';
import { ReactComponent as data_up } from './Svg/data_up.svg';
import { ReactComponent as delete_filled } from './Svg/delete_filled.svg';
import { ReactComponent as delete_outline } from './Svg/delete_outline.svg';
import { ReactComponent as diagonal_arrow_right } from './Svg/diagonal_arrow_right.svg';
import { ReactComponent as download } from './Svg/download.svg';
import { ReactComponent as dropdown } from './Svg/dropdown.svg';
import { ReactComponent as duplicate } from './Svg/duplicate.svg';
import { ReactComponent as edit } from './Svg/edit.svg';
import { ReactComponent as edit_profile } from './Svg/edit_profile.svg';
import { ReactComponent as edit_team } from './Svg/edit_team.svg';
import { ReactComponent as enter_ai } from './Svg/enter_ai.svg';
import { ReactComponent as expand_all } from './Svg/expand_all.svg';
import { ReactComponent as expand_down } from './Svg/expand_down.svg';
import { ReactComponent as expand_right } from './Svg/expand_right.svg';
import { ReactComponent as eye } from "./Svg/eye-svgrepo-com.svg";
import { ReactComponent as file_filled } from './Svg/file_filled.svg';
import { ReactComponent as file_outline } from './Svg/file_outline.svg';
import { ReactComponent as filter } from "./Svg/filter.svg";
import { ReactComponent as folder_archive } from './Svg/folder_archive.svg';
import { ReactComponent as folder_archive_outline } from './Svg/folder_archive_outline.svg';
import { ReactComponent as folder_big_ai} from './Svg/folder_big_ai.svg'
import { ReactComponent as folder_outline } from './Svg/folder_outline.svg';
import { ReactComponent as folder_sort } from './Svg/folder_sort.svg';
import { ReactComponent as go } from './Svg/go.svg';
import { ReactComponent as home_outline } from './Svg/home_outline.svg';
import { ReactComponent as info_outline } from './Svg/info_outline.svg';
import { ReactComponent as information } from './Svg/information.svg';
import { ReactComponent as invoice } from './Svg/invoice.svg';
import { ReactComponent as linkedn } from './Svg/linkedin_icon.svg';
import { ReactComponent as list } from './Svg/list-svgrepo-com.svg';
import { ReactComponent as logout } from './Svg/logout.svg';
import { ReactComponent as ml } from './Svg/machine-learning.svg';
import { ReactComponent as mail_open } from './Svg/mail-open.svg';
import { ReactComponent as main_arrow } from './Svg/main_arrow.svg';
import { ReactComponent as merge } from './Svg/merge.svg';
import { ReactComponent as method_blank_big } from './Svg/method_blank_big.svg';
import { ReactComponent as method_blank_small } from './Svg/method_blank_small.svg';
import { ReactComponent as model_orange } from './Svg/model_orange.svg';
import { ReactComponent as model_teal } from './Svg/model_teal.svg';
import { ReactComponent as new_study_filled } from './Svg/new_study_filled.svg';
import { ReactComponent as number_1 } from './Svg/number_1.svg';
import { ReactComponent as number_2 } from './Svg/number_2.svg';
import { ReactComponent as number_3 } from './Svg/number_3.svg';
import { ReactComponent as number_4 } from './Svg/number_4.svg';
import { ReactComponent as organization } from './Svg/organization.svg';
import { ReactComponent as onboarding } from './Svg/onboarding.svg';
import { ReactComponent as output_insights } from './Svg/output_insights.svg';
import { ReactComponent as overview } from './Svg/overview.svg';
import { ReactComponent as person_filled } from './Svg/person_filled.svg';
import { ReactComponent as plus_version } from './Svg/plus_version.svg';
import { ReactComponent as pre_check } from './Svg/pre_check.svg';
import { ReactComponent as renew } from './Svg/renew.svg';
import { ReactComponent as reset } from './Svg/reset-svgrepo-com.svg';
import { ReactComponent as reset_white } from './Svg/reset-white.svg';
import { ReactComponent as settings_filled } from './Svg/settings_filled.svg';
import { ReactComponent as search } from './Svg/search.svg';
import { ReactComponent as share } from './Svg/share.svg';
import { ReactComponent as sort_by } from './Svg/sort_by.svg';
import { ReactComponent as sort_az } from './Svg/sort_az.svg';
import { ReactComponent as sort_num_down } from './Svg/sort_num_down.svg';
import { ReactComponent as sort_num_up } from './Svg/sort_num_up.svg';
import { ReactComponent as sort_za } from './Svg/sort_za.svg';
import { ReactComponent as statistic_performance } from './Svg/statistic_performance.svg';
import { ReactComponent as study_filled } from './Svg/study_filled.svg';
import { ReactComponent as study_outline } from './Svg/study_outline.svg';
import { ReactComponent as support } from './Svg/support.svg';
import { ReactComponent as tile_card } from './Svg/tile-card-list-svgrepo-com.svg';
import { ReactComponent as timer } from './Svg/timer.svg';
import { ReactComponent as tickbox30 } from './Svg/tick-box-30.svg';
import { ReactComponent as unfilter } from "./Svg/unfilter.svg";
import { ReactComponent as upload } from './Svg/upload.svg';
import { ReactComponent as uprightarrow } from './Svg/uprightarrow.svg';
import { ReactComponent as warning} from './Svg/warning.svg'
import { ReactComponent as xing } from './Svg/xing_icon.svg';
import { ReactComponent as remove } from './Svg/remove.svg';
import { ReactComponent as anonymization_method} from './Svg/anonymization_method.svg'
import { ReactComponent as compliance_check} from './Svg/compliance_check.svg'
import { ReactComponent as language_detection} from './Svg/language_detection.svg'
import { ReactComponent as sanity_method} from './Svg/sanity_method.svg'
import { ReactComponent as sentiment_method} from './Svg/sentiment_method.svg'
import { ReactComponent as thematic_analysis} from './Svg/thematic_analysis.svg'
import { ReactComponent as train_from_scratch} from './Svg/train_from_scratch.svg'
import { ReactComponent as upload_model_file} from './Svg/upload_model_file.svg'
import { ReactComponent as wait} from './Svg/wait.svg'


const icons = {
  add,
  add_small,
  add_vault_file,
  add_version,
  ai_circle_white,
  alert,
  analysis_wizard,
  anonymization,
  anonymization_color,
  assist,
  caution_error,
  check_filled,
  circle,
  circle_2,
  circle_3,
  close,
  close_small,
  collapse_all,
  contact_mail,
  contact_phone,
  check,
  click,
  costs_outline,
  credits,
  data_analysis,
  data_down,
  data_up,
  delete_filled,
  delete_outline,
  diagonal_arrow_right,
  download,
  dropdown,
  duplicate,
  edit,
  edit_profile,
  edit_team,
  enter_ai,
  expand_all,
  expand_down,
  expand_right,
  eye,
  file_filled,
  file_outline,
  filter,
  folder_archive,
  folder_archive_outline,
  folder_big_ai,
  folder_outline,
  folder_sort,
  go,
  home_outline,
  info_outline,
  information,
  invoice,
  linkedn,
  list,
  logout,
  ml,
  mail_open,
  main_arrow,
  merge,
  method_blank_big,
  method_blank_small,
  model_orange,
  model_teal,
  new_study_filled,
  number_1,
  number_2,
  number_3,
  number_4,
  onboarding,
  organization,
  output_insights,
  overview,
  person_filled,
  plus_version,
  pre_check,
  renew,
  reset,
  reset_white,
  settings_filled,
  search,
  share,
  sort_by,
  sort_az,
  sort_num_down,
  sort_num_up,
  sort_za,
  statistic_performance,
  study_filled,
  study_outline,
  support,
  tickbox30,
  tile_card,
  timer,
  unfilter,
  upload,
  uprightarrow,
  warning,
  xing,
  remove,
  anonymization_method,
  compliance_check,
  language_detection,
  sanity_method,
  sentiment_method,
  thematic_analysis,
  train_from_scratch,
  upload_model_file,
  wait
};

export default class Icon extends React.PureComponent {
  render() {
    const { name, size = 32 } = this.props;

    const Icon = icons[name];

    if (!Icon) {
      const existsIcons = Object.keys(icons).join(', ');

      throw new Error(`icon with name "${name}" not exists. Following icons exists: ${existsIcons}`);
    }
    return <Icon {...this.props} width={size} height={size} />;
  }
}

export class HomePageIcon extends React.PureComponent {
  render() {
    const { name, width, height } = this.props;

    const Icon = icons[name];

    if (!Icon) {
      const existsIcons = Object.keys(icons).join(', ');

      throw new Error(`icon with name "${name}" not exists. Following icons exists: ${existsIcons}`);
    }

    return <Icon width={width} height={height} />;
  }
}
