import React, {useState, useEffect} from "react";
import {Link, withRouter} from "react-router-dom";

import styled from "@emotion/styled";

import {ReactComponent as LogoEarlyAccess} from './logo_early_access.svg';
import {ReactComponent as Logo} from './logo.svg';
import {ReactComponent as LogoLittle} from './logo_deepsight_short_white.svg';

import tokenStorage from "../../security/TokenStorage";
import {isDeepsightAdmin} from '../../security/Role';
import {withTranslation} from "react-i18next";
import {get} from "../../security/Api";
import DialogResponsive from "../DialogResponsive";
import SubMenu from "./SubMenu";
import {darkblue, white, grey30} from '../../colors';
import Icon from "../Icon/Icon";

const Head = styled.header`
    position: fixed;
    z-index: 20;
    width: 100%;
    display: flex;
    align-items: center;
    background: ${props => props.token ? 'black' : props.light ? 'transparent' : "transparent"};
    height: 60px;
    padding: 16px 56px 10px 56px;
`;

const Brand = styled.div`
    width: 150px;
`;

const LogoContainer = styled.div`
    display: flex;
    justify-content: left;
`;

const NavigationContainer = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
`;

const LeftNavigation = styled.div`
    display: inline-flex;
    justify-content: space-between;
    margin-left: 48px;
`;

const RightNavigation = styled.div`
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
`;

const IconContainer = styled.div`
    display: inline-flex;
    vertical-align: middle;
`;

// const BoldHeader = styled.div`
//     font-weight: bolder;
// `;
//
// const BlueItalic = styled.div`
//     font-style: italic;
//     color: blue;
// `;

const NavLogout = styled(Link)`
    color: ${props => props.selected ? white : grey30};
    text-decoration: none;
    font-family: AvenirLTPro-Black, AvenirNextCyrBold;
    cursor: pointer;
    display: flex;
    margin-right: 56px;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 7px;

    &:hover {
        color: ${white};
    }
`;

const DocLink = styled(Link)`
  color: ${props => props.selected ? white : grey30};
  text-decoration: none;
  font-family: AvenirNextLTPro-Demi, AvenirNextCyrDemi;
  display: block;
  border-bottom: 2px solid ${props => props.selected ? white : 'transparent'};
  margin-right: 56px;
  font-size: 16px;
  line-height: 22px;

  &:hover {
    color: ${white};
    border-bottom: 2px solid ${white};
  }
`;

const NavLink = styled(Link)`
  color: ${props => props.selected ? white : grey30};
  text-decoration: none;
  font-family: AvenirNextLTPro-Demi, AvenirNextCyrDemi;
  display: block;
  border-bottom: 2px solid ${props => props.selected ? white : 'transparent'};
  margin-right: 56px;
  font-size: 16px;
  line-height: 22px;

  &:hover {
    color: ${white};
    border-bottom: 2px solid ${white};
  }
`;

function Header(props) {

  const {t, startTour, skipTour, setUserFirstName} = props;

  const [token, setToken] = useState(tokenStorage.getData());
  const {light} = props;
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth
  })
  const [isModalBeforeTourOpen, setIsModalBeforeTourOpen] = useState(false);
  // const [isTourOpen, setIsTourOpen] = useState(false);

  const [currentUserName, setCurrentUserName] = useState("Friend");
  // const [scrollingEnabled, setScrollingEnabled] = useState(false);

  // console.log(params.pathname);
  // console.log(window.location.pathname);

  if (window.location.pathname === "/" || window.location.pathname === "/contactus") {
    props.setLight(false);
  } else if (window.location.pathname === "/registration" || window.location.pathname === "/login") {
    console.log(window.location.pathname);
    props.setLight(true);
  }

  useEffect(() => {
    // props.setLight(window.location.pathname === "/");
    tokenStorage.subscribe(() => {
      setToken(tokenStorage.getData());
    });

    // console.log(location.pathname);
    function handleResize() {
      setDimensions({
        width: window.innerWidth
      })
    }

    window.addEventListener('resize', handleResize)

    // window.location.pathname === "/login" || window.location.pathname === "/registration" ? props.setLight(true) : props.setLight(false);
  }, []);

  useEffect(() => {
    if (window.location.pathname === '/project') {
      setTimeout(() => loadUser(), 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  // const enableScroll = () => {
  //   document.body.style.overflow = "scroll";
  // };

  // const diasableScroll = () => {
  //   document.body.style.overflow = "hidden";
  // };

  const loadUser = async () => {
    const response = await get(`/user/detail`);

    if (response.status === 200) {
      const responseData = await response.json();

      if(!responseData.isOnboardingPassed && window.location.pathname === '/project') {
        // document.body.style.overflow = "hidden";
        // setIsTourOpen(true);
        setIsModalBeforeTourOpen(true);
        setCurrentUserName(responseData.firstName ? responseData.firstName : "Friend");
        setUserFirstName(responseData.firstName ? responseData.firstName : "Friend");
      }
      // else {
      //   setScrollingEnabled(true);
      // }
    }
  };

  const makeHeaderDark = () => {
    props.setLight(false);
  }

  const startTourAgain = () => {
    setIsModalBeforeTourOpen(true);
    props.history.push('/project');
  }

  function renderUserNavigation() {

    return (
      <NavigationContainer>
        <LeftNavigation>
          <NavLink to={'/project'}>{t("header1", "Projekte")}</NavLink>
          <NavLink to={'/organisation/files'}>{t("header7", "Dateiliste")}</NavLink>
          <NavLink to={'/organisation/models'}>{t("headerTodo100", "Models")}</NavLink>
          <NavLink to={'/history'}>{t("header6", "Historie")}</NavLink>
          <DocLink to={{pathname: "https://docs.deepsight.de"}}
                   target="_blank">{t("documentation", "Dokumentation")}</DocLink>

        </LeftNavigation>

        <RightNavigation>
          {/*<NavLink  to={'/credits/buy'}>*/}
          {/*  <IconContainer>*/}
          {/*    <Icon size={24}*/}
          {/*          name="credits"/>*/}
          {/*  </IconContainer> {t("header2", "Credits")}*/}
          {/*</NavLink>*/}
          <NavLink data-tut="step_three" to={'/account?form=user'}>
            <IconContainer>
              <Icon size={24}
                    name="settings_filled"/>
            </IconContainer>
            {t("header3", "Account")}
          </NavLink>

          <SubMenu data-tut="step_fifteen" t={t} startTourAgain={startTourAgain}/>

        </RightNavigation>


      </NavigationContainer>
    );
  }

  function renderAnonymouseNavigation() {
    return (
      <NavigationContainer>
        <LeftNavigation>
        </LeftNavigation>
        {/*<RightNavigation>*/}
        {/*  {!light &&*/}
        {/*  <NavLink*/}
        {/*    onClick={() => makeLight()}*/}
        {/*    data-cy="login"*/}
        {/*    to={'/login'}>*/}
        {/*    Login*/}
        {/*  </NavLink>*/}
        {/*  }*/}
        {/*  {!light &&*/}
        {/*  <NavLink*/}
        {/*    data-cy="registration"*/}
        {/*    to={'/registration'}*/}
        {/*    onClick={() => makeLight()}*/}
        {/*  >*/}
        {/*    {t("header4", "Registrierung")}*/}
        {/*  </NavLink>*/}
        {/*  }*/}
        {/*</RightNavigation>*/}
      </NavigationContainer>
    );
  }

  const handleCloseDialog = () => {
    setIsModalBeforeTourOpen(false);
    // document.body.style.overflow = "scroll";
    document.body.style.overflow = "auto";
  };

  function renderAdminNavigation() {
    return (
      <NavigationContainer>
        <LeftNavigation>
        </LeftNavigation>

        <RightNavigation>
          <NavLink to={'/organisation/list'}>{t("header5", "Organisation(en)")}</NavLink>
          <NavLogout to={'#'} onClick={() => tokenStorage.logout()}><IconContainer><Icon name="logout"
                                                                                         size={24}/></IconContainer></NavLogout>
        </RightNavigation>
      </NavigationContainer>
    );
  }

  return (
    <Head light={light} token={token}>
      <Brand>
        {token &&
        <Link to="/project">
          <LogoContainer
            onClick={() => makeHeaderDark()}>
            {!token && dimensions.width < 900 &&
            <LogoLittle
              style={light ? {fill: darkblue} : {fill: grey30}}
              alt="Logo"
            />
            }

            {!token && dimensions.width >= 900 &&
            <Logo
              style={{fill: darkblue}}
              alt="Logo"
            />
            }

            {token &&
            <LogoEarlyAccess
              style={light ? {fill: darkblue} : {}}
              alt="Logo"
            />
            }
          </LogoContainer>
        </Link>
        }
        {!token &&
        <Link to={{pathname: "https://deepsight.de/data-desk/"}} target="_blank">
          <LogoContainer
            onClick={() => makeHeaderDark()}>
            {!token && dimensions.width < 900 &&
            <LogoLittle
              style={light ? {fill: darkblue} : {fill: grey30}}
              alt="Logo"
            />
            }

            {!token && dimensions.width >= 900 &&
            <Logo
              style={{fill: darkblue}}
              alt="Logo"
            />
            }

            {token &&
            <LogoEarlyAccess
              style={light ? {fill: darkblue} : {}}
              alt="Logo"
            />
            }
          </LogoContainer>
        </Link>
        }
      </Brand>

      <DialogResponsive
        isModalOpen={isModalBeforeTourOpen}
        handleCloseDialog={handleCloseDialog}
        startTour={() => {
          // document.body.style.overflow = "hidden";
          setIsModalBeforeTourOpen(false);
          startTour()}}
        skipTour={() => {
          skipTour();
          setIsModalBeforeTourOpen(false);
        }}
        userName={currentUserName}
      />
      {!token ? renderAnonymouseNavigation() : isDeepsightAdmin() ? renderAdminNavigation() : renderUserNavigation()}
    </Head>
  );
  // }
}

export default withRouter(withTranslation("translations")(Header));
