import tokenStorage from "./TokenStorage";

export async function get(path) {
    const url = getUrl(path);
    const token = tokenStorage.getToken();

    let headers = {
        'Content-Type': 'application/json'
    }

    if (token !== null) {
        headers['X-AUTH-TOKEN'] = 'Bearer ' + token;
    }

    let response;

    try {
        response = await fetch(url, {
            method: 'GET',
            headers: headers
        });
    } catch (e) {
        console.log('GET', url, e);
    }

    if(response) {
      console.log(response);
      if (response.status === 401) {
        tokenStorage.expired();
        throw new Error('unauthorized');
      }
    }
    return response;
}

export async function post_simple(path, data) {
    const url = getUrl(path);
    const token = tokenStorage.getToken();

    let headers = {
        'Content-Type': 'application/json'
    }

    if (token !== null) {
        headers['X-AUTH-TOKEN'] = 'Bearer ' + token;
    }

    let response;

    try {
        response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        });

    } catch (e) {
        console.log('POST', url, data, e);
    }

    if (response.status === 401) {
        tokenStorage.expired();

        throw new Error('unauthorized');
    }

    return response;
}

export async function post(path, data, onError, onSuccess) {
    const url = getUrl(path);
    const token = tokenStorage.getToken();

    let headers = {
        'Content-Type': 'application/json'
    }

    if (token !== null) {
        headers['X-AUTH-TOKEN'] = 'Bearer ' + token;
    }

    let response;

    try {
        response = await fetch(url, {
            // signal: AbortSignal.timeout(5000),
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        });

    } catch (e) {
        console.log('POST', url, data, e);
        onError(0);
    }

    if (response?.status === 401) {
        tokenStorage.expired();
        throw new Error('unauthorized');
    }

    try {
        let responseData = await response.json();
        if (typeof onError !== 'undefined' && typeof responseData !== 'undefined' && typeof onSuccess !== 'undefined') {
            if (typeof responseData.code !== 'undefined')
            {
                if (responseData.code === 450){ //Validation error
                    let code = parseInt(responseData.data[0].message);
                    onError(code);
                }else{
                    onError(responseData.code);
                }

            } else {
                onSuccess(responseData);
            }
        }
    } catch (e) {
        console.log('POST', url, data, e);
        onError(0);
    }

    // response.status = 504;

    console.log(response);
    return response;

}

export async function upload(path, file) {
    const url = getUrl(path);
    const token = tokenStorage.getToken();

    let headers = {};

    if (token !== null) {
        headers['X-AUTH-TOKEN'] = 'Bearer ' + token;
    }

    let response;

    const formData = new FormData();
    formData.append('file', file);

    try {
        response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: formData
        });
    } catch (e) {
        apiError('POST', url, file);
    }

    console.log(response);

    if (response.status === 401) {
        tokenStorage.expired();

        throw new Error('unauthorized');
    }

    if (response.status === 400) {
        console.log(JSON.stringify(response.data));
    }

    return response;
}

export async function login(email, password) {
    const url = getUrl('/login');
    const data = { username: email || '', password: password || '' };
    console.log(url);
    try {
        return await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify(data)
        });
    } catch (e) {
        apiError('POST', url, data);
    }
}

function getUrl(path) {
    return process.env.REACT_APP_API_URL + path;
}

function apiError(method, url, data) {
    throw new Error(`Api call Fail with method="${method}", url="${url}", data="${JSON.stringify(data)}"`);
}
