import React from 'react';
import styled from '@emotion/styled';
import {Link} from 'react-router-dom';
import {
  buttonmain,
  buttonmainhover,
  blue,
  limegreen,
  red,
  bluedarker,
  darkblue,
  grey30,
  grey10,
  orange1,
  white,
  black,
  cancel,
  cancelbg
} from '../colors';
import Icon from './Icon/Icon';

const ButtonComponent = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width ? props.width : 'auto'}
  ${props => !props.minWidth && 'min-width: 40%;'};
  // ${props => !props.text && 'width: 54px;'};
  
  ${props => props.fullWidth && 'width: 100%;'};
  ${props => props.marginLeft && 'margin-left: 10px;'};
  
  padding: ${props => props.narrow ? '10px 10px 10px 0' : props.text ? '16px 24px 16px 24px' : props.tiny ? '16px 24px 16px 24px' : props.skinny ? '8px 12px 8px 12px' : '11px'};
  
  height: ${props => props.small ? '54px' : props.tiny ? '32px' : props.middle ? '41px' : props.narrow ? '34px' : props.skinny ? '25px' : '56px'};

  line-height: ${props => props.small ? '54px' : props.tiny ? '32px' : props.narrow ? '32px' : props.skinny ? '5px' : '56px'};

  background: ${props => {
  if (props.green) {
    return limegreen;
  }
  if (props.disabled) {
    return grey30;
  }
  if (props.delete && !props.outline) {
    return orange1;
  }
  if (props.cancel) {
    return cancelbg;
  }
  if (props.danger && !props.outline) {
    return 'transparent';
  }
  if (props.greyscale) {
    return grey10;
  }
  if (props.mainbutton) {
    return buttonmain;
  }
  if (props.darkblue) {
    return darkblue;
  }
  if (props.color) {
    return props.color;
  }
  return props.outline ? 'transparent' : blue;
}};

  color: ${props => {
  if (props.outline) {
    return darkblue
  }
  if (props.danger) {
    return red
  }
  if (props.greyscale) {
    return black
  }
  if (props.cancel) {
    return cancel
  }
  return white
}};
  
  border: 3px solid ${props => {
  if (props.green) {
    return limegreen;
  }
  if (props.disabled) {
    return grey30;
  }
  if (props.delete) {
    return orange1;
  }
  if (props.danger) {
    return red;
  }
  if (props.greyscale) {
    return grey10;
  }
  if (props.cancel) {
    return cancelbg;
  }
  if (props.mainbutton) {
    return buttonmain;
  }
  if (props.darkblue) {
    return darkblue;
  }
  if (props.color) {
    return props.color;
  }
  if (props.color) {
    return props.color;
  }
}};
  
  border-radius: ${props => props.rounded ? '100px' : props.rectangle ? '0px' : '4px'};

  appearance: none;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  
  letter-spacing: 0.75px;
  font-family: AvenirLTPro-Black, AvenirNextCyrBold;
  font-size: ${props => props.tiny ? '12px' : props.skinny ? '12px' : '15px'};
  text-transform: uppercase;
  ${props => props.texttransformnone && 'text-transform: none;'};
  text-decoration: none;
  text-align: left;

  transition: background-color 0.3s, border-color 0.3s, color 0.3s;

  ${props => !props.disabled && !props.delete && !props.danger && `
      &:hover {
        color: ${white};
        background: ${bluedarker};
        border-color ${bluedarker};
      };
  `};

  ${props => props.danger && `
      &:hover {
        color: ${white};
        background: ${red};
        border-color ${red};
      };
  `};
  
  ${props => props.greyscale && `
      &:hover {
        color: ${black};
        background: ${grey30};
        border-color ${grey30};
      };
  `};

  ${props => props.cancel && `
      &:hover {
        color: ${white};
        background: ${cancel};
        border-color ${cancel};
      };
  `};
  
  ${props => props.mainbutton && `
      &:hover {
        color: ${white};
        background: ${buttonmainhover};
        border-color ${buttonmainhover};
      };
  `};
  
   ${props => props.green && `
      &:hover {
        color: ${white};
        background: ${limegreen};
        border-color ${limegreen};
      };
  `};  
   
   ${props => props.darkblue && `
      &:hover {
        color: ${white};
        background: ${buttonmainhover};
        border-color ${buttonmainhover};
      };
  `};
  
    ${props => props.disabled && `
      &:hover {
        color: ${white};
        background: ${grey30};
        border-color ${grey30};
      };
  `};

  > svg {
    flex-shrink: 0;
    ${props => props.text && props.icon && !props.tiny && !props.narrow && 'margin-right: 16px;'}
    ${props => props.tiny && props.icon && 'width: 20px; height: 20px; margin-right: 6px;'}
  }
`;

export const LinkButtonComponent = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => !props.minWidth && 'min-width: 40%;'};

  height: ${props => props.small ? '54px' : props.tiny ? '32px' : props.middle ? '41px' : '56px'};

  background: ${props => {
  if (props.disabled) {
    return grey30;
  }
  if (props.delete) {
    return orange1;
  }
  if (props.white) {
    return 'transparent';
  }
  if (props.mainoutlined) {
    return 'white';
  }
  if (props.mainbutton) {
    return buttonmain;
  }
  return props.outline ? 'transparent' : blue;
}};

  color: ${props => {
  if (props.mainoutlined) {
    return buttonmain;
  }
  return props.outline ? blue : white;
}};
  
  border: ${props => {
  if (props.disabled) {
    return grey30;
  }
  if (props.white) {
    return white;
  }
  if (props.mainoutlined) {
    return '3px solid' + buttonmain;
  }
  if (props.mainbutton) {
    return buttonmain;
  }
  return props.white ? white : blue;
}};

  border-radius: ${props => {
  if (props.rounded) {
    return "100px;";
  }
  return '4px';
}};

  appearance: none;
  cursor: pointer;
  letter-spacing: 0.75px;
  font-family: AvenirLTPro-Black, AvenirNextCyrBold;
  font-size: 15px;
  ${props => props.texttransformnone && 'font-size: 19px;'};
  text-transform: uppercase;
  ${props => props.texttransformnone && 'text-transform: none;'};
  text-decoration: none;
  text-align: left;

  padding: ${props => props.text ? '18px 28px 16px 24px' : ''};
  padding: ${props => props.minwidth ? '18px 0 18px 0' : ''};

  ${props => !props.text && 'width: 54px;'};

  line-height: ${props => props.small ? '54px' : '56px'};
  line-height: ${props => props.tiny ? '36px' : '56px'};

  transition: background-color 0.3s, border-color 0.3s, color 0.3s;

  ${props => props.outline && `
    &:hover {
        > svg {
            color: ${white};
        }
    }
  `};

  ${props => !props.disabled && `
  &:hover {
    color: ${white};
    background: ${bluedarker};
    border-color ${bluedarker};
  };
  `};
  
    ${props => props.mainoutlined && `
    &:hover {
      color: ${white};
      background: ${buttonmainhover};
      border-color ${buttonmainhover};    
        > svg {
            color: ${white};
        }
    }
  `};
  
    ${props => props.mainbutton && `
      &:hover {
        color: ${white};
        background: ${buttonmainhover};
        border-color ${buttonmainhover};
      };
  `};


  > svg {
    ${props => props.outline && `color: ${blue};`};
    ${props => props.text && props.icon && 'margin-right: 16px;'};
  }
`;

export const ButtonText = styled.div`
  margin-bottom: -4px;
  line-height: 20px;
`;

export const ButtonRightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 65px;
  width: 100%;
`;

export const LinkIconComponent = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;

  height: ${props => props.small ? '50px' : props.tiny ? '32px' : '56px'};

  color: ${darkblue};

  appearance: none;
  cursor: pointer;
  border-radius: 4px;
  letter-spacing: 0.75px;
  font-family: inherit;
  font-size: 1rem;
  font-family: AvenirLTPro-Black, AvenirNextCyrBold;
  text-transform: uppercase;
  text-decoration: none;
  text-align: left;
  padding: 0 20px;

  line-height: ${props => props.small ? '50px' : '56px'};

  transition: background-color 0.3s, border-color 0.3s, color 0.3s;

  > svg {
    width: 40px;
    height: 40px;
    ${props => props.text && props.icon && 'margin-right: 16px;'}
  }
`;

export class Button extends React.PureComponent {

  render() {
    const {right} = this.props;

    return (
      <ButtonComponent
        {...this.props}
        text={this.props.children !== undefined ? "true" : undefined}
        right={right}
        onClick={event => {
          event.preventDefault();
          this.props.onClick();
        }}>

        {this.props.icon && !right && <Icon name={this.props.icon}/>}

        <ButtonText>
          {this.props.children}
        </ButtonText>

        {this.props.icon && right && <Icon name={this.props.icon}/>}

      </ButtonComponent>
    );
  }
}

export class LinkButton extends React.PureComponent {
  render() {
    return (
      <LinkButtonComponent
        {...this.props}
        text={this.props.children !== undefined ? "true" : undefined}
      >

        {this.props.icon && <Icon name={this.props.icon}/>}
        <ButtonText>
          {this.props.children}
        </ButtonText>
      </LinkButtonComponent>
    );
  }
}

export class LinkIcon extends React.PureComponent {
  render() {
    const {icon} = this.props;

    return (
      <LinkIconComponent {...this.props} text={this.props.children !== undefined ? "true" : undefined}>
        <Icon name={icon}/>
      </LinkIconComponent>
    );
  }
}
